<template>
  <div>
    <!-- 上半部 -->
    <div class="row mb-1" v-if="lineGroupLinkIds.length > 0">
      <!-- 按鈕 -->
      <div class="col-md-5 col-12 mb-1">
        <button
          class="tw-btn tw-btn-secondary me-2"
          @click="showModal('batchSetStatus')"
        >
          批次設定狀態
        </button>
        <button
          class="tw-btn tw-btn-secondary me-2"
          @click="showModal('advancedSearch')"
        >
          進階搜尋
        </button>
      </div>
      <!-- 訊息 -->
      <div class="col-md-7 col-12 mb-1 fw-bolder">
        <p>
          <span v-if="recordAdvancedSearchData.timeRange.switch">
            時間區間:
            <span
              v-if="
                recordAdvancedSearchData.timeRange.startTime &&
                recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.startTime }}~{{
                recordAdvancedSearchData.timeRange.endTime
              }}</span
            >
            <span
              v-if="
                recordAdvancedSearchData.timeRange.startTime &&
                !recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.startTime }}開始</span
            >
            <span
              v-if="
                !recordAdvancedSearchData.timeRange.startTime &&
                recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.endTime }}以前</span
            >&ensp;
          </span>
        </p>
      </div>
    </div>
    <p class="mx-3 fw-bolder alert alert-info" v-else>
      您尚未統綁定任 Line Bot ！
      <router-link :to="`/seller/store/${storeId}/social`" 點我立即前往綁定
        >>></router-link
      >
    </p>
    <!-- dataTable -->
    <div class="tw-container">
      <div class="management-datatable">
        <DataTable
          class="p-datatable-sm"
          :scrollHeight="`${scrollHeight}px`"
          :value="wishesList"
          dataKey="id"
          :loading="dataTabelLoading"
          :rowHover="true"
          :paginator="true"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rows="20"
          :rowsPerPageOptions="[20, 50, 100]"
          :scrollable="true"
          currentPageReportTemplate="從第 {first} 筆到 第 {last} 筆，總共 {totalRecords} 筆"
          v-model:filters="searchGlobal"
          filterDisplay="menue"
          stateStorage="local"
          stateKey="dt-state-makewishes-local"
          sortField="createTime"
          :sortOrder="-1"
          v-model:selection="selectItems"
          v-model:expandedRows="expandedRows"
          stripedRows
        >
          <template #header>
            <div class="d-flex align-items-center mb-2">
              <input
                class="form-control me-2"
                v-model="searchGlobal['global'].value"
                placeholder="表格搜尋: ID，投票總數，許願者，許願群組，商品名稱，產地國家，許願時間"
                style="max-width: 99%"
              />
            </div>
            <div>
              <button
                class="tw-btn tw-btn-success me-2 mb-2"
                @click="expandAll"
              >
                ＋展開全部</button
              ><button class="tw-btn tw-btn-success mb-2" @click="collapseAll">
                －收合全部
              </button>
            </div>
          </template>
          <Column :expander="true" style="max-width: 50px" />
          <!-- 選取 -->
          <Column selectionMode="multiple" style="max-width: 50px"></Column>
          <!-- ID -->
          <Column field="id" header="ID" sortable> </Column>
          <!-- 投票總數 -->
          <Column
            field="totalVotes"
            header="投票總數"
            sortable
            style="min-width: 80px; max-width: 80px"
          >
          </Column>
          <!-- 狀態 -->
          <Column
            field="completed"
            header="狀態"
            style="min-width: 100px; max-width: 100px"
          >
            <template #body="{ data }">
              <p
                class="fw-bolder text-warning pointer can-click"
                v-if="data.completed"
                @click="updeteCompleted('single', data)"
              >
                已結束
              </p>
              <p
                class="fw-bolder text-success pointer can-click"
                v-else
                @click="updeteCompleted('single', data)"
              >
                進行中
              </p>
            </template>
          </Column>
          <!-- 許願者 -->
          <Column
            field="creater"
            header="許願者"
            style="min-width: 150px; max-width: 150px"
            sortable
          >
            <template #body="{ data }">
              <p class="text-break">{{ data.creater }}</p>
            </template>
          </Column>
          <!-- 許願群組 -->
          <Column
            field="lineGroupName"
            header="許願群組"
            style="min-width: 150px"
          >
            <template #body="{ data }">
              <p class="text-break">{{ data.lineGroupName }}</p>
            </template>
          </Column>
          <!-- 頭像 -->
          <Column
            field=""
            header="圖示"
            style="min-width: 90px; max-width: 90px"
          >
            <template #body="{ data }">
              <div class="d-flex align-items-start">
                <Image
                  :imageHash="data.imageHash"
                  size="s"
                  v-if="data.imageHash"
                  :alt="data.imageHash"
                  style="width: 50px; height: 50px"
                ></Image>
              </div>
            </template>
          </Column>
          <!-- 商品名稱 -->
          <Column
            field="name"
            header="商品"
            style="min-width: 150px; max-width: 150px"
            sortable
          >
            <template #body="{ data }">
              <p class="text-break">{{ data.name }}</p>
            </template>
          </Column>
          <!-- 產地國家 -->
          <Column
            field="country"
            header="產地國家"
            style="min-width: 150px; max-width: 150px"
            sortable
          >
            <template #body="{ data }">
              <p class="text-break">{{ data.country }}</p>
            </template>
          </Column>
          <!-- 商品描述 -->
          <Column
            field="description"
            header="商品描述"
            style="min-width: 200px"
            sortable
          >
            <template #body="{ data }">
              <p class="text-break">{{ data.description }}</p>
            </template>
          </Column>
          <!-- 許願時間 -->
          <Column
            field="createTime"
            header="許願時間"
            sortable
            style="min-width: 200px"
          >
          </Column>
          <!-- 單身 -->
          <template #expansion="slotProps">
            <p class="text-primary fw-bolder mb-2">誰有集氣~</p>
            <ul v-if="slotProps.data.wishVotes.length > 0">
              <li class="row">
                <div class="col-4 fw-bolder">集氣者</div>
                <div class="col-8 fw-bolder">集氣時間</div>
              </li>
              <hr />
              <li
                class="row mb-1"
                v-for="body in slotProps.data.wishVotes"
                :key="body.id"
              >
                <div class="col-4">
                  {{ body.appUserName }}
                </div>
                <div class="col-8">
                  {{ body.createTime }}
                </div>
              </li>
            </ul>
            <p class="text-danger fw-bolder" v-else>喔 NO ~ 沒有人集氣 ˊ ˋ</p>
          </template>
          <template #empty>
            <p class="text-center fw-bolder text-primary">
              目前尚未有任何資料 0..0
            </p>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
  <!-- 批次設定狀態的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="batchSetStatusModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">批次設定狀態</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p>確定改變以上幾筆許願商品的狀態嗎 ?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updeteCompleted('multiple')"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 進階搜尋的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="advancedSearchModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">進階搜尋</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 時間區間 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_TimeSwitch"
                v-model="advancedSearchData.timeRange.switch"
              />
              <label class="form-check-label" for="AS_TimeSwitch"
                >時間區間</label
              >
            </div>
            <!-- 時間 -->
            <div class="row mb-3" v-if="advancedSearchData.timeRange.switch">
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_StartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.timeRange.startTime"
                  />
                  <label for="AS_StartTime" class="mb-2">起始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_EndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.timeRange.endTime"
                  />
                  <label for="AS_EndTime" class="mb-2">結束時間</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 篩選條件 -->
          <!-- <div class="border mb-3 p-3">
            <p class="tw-border-start fw-bolder">篩選條件</p>
            <hr />
            <ul class="list">
              <li
                class="row border-bottom"
                v-for="data in singleSelectsArray"
                :key="data.id"
              >
                <div class="col-3 my-2">{{ data.key }}</div>
                <div class="col-9">
                  <div
                    class="select-radio my-2"
                    v-for="select in data.value"
                    :key="select.id"
                  >
                    <label>
                      <input
                        type="radio"
                        :name="data.id"
                        :value="select.value"
                        v-model="advancedSearchData.singleSelect[data.id]"
                      />
                      <span class="radio-style">{{ select.value }}</span>
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div> -->
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="advancedSearch(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// dataTable
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                     //optional for row
import { FilterMatchMode } from 'primevue/api';

export default {
  components: {DataTable, Column, ColumnGroup, Row},
  data() {
    return {
      // modal
      batchSetStatusModal: {},
      advancedSearchModal: {},
      // data
      serverToken: '',
      storeId: 0,
      // dataTable
      scrollWidth: 0,
      scrollHeight: 0,
      dataTabelLoading: false,
      searchGlobal: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS
        }
      },
      // 選擇到的物件
      selectItems: [],
      expandedRows: [],
      // modal 內呈現選擇到的物件資訊
      propsSelectInfos: {
        show: 'id',
        data: []
      },
      lineGroupLinkIds: [],
      wishesList: [],
      // 進階搜尋
      advancedSearchData: {
        timeRange: {
          switch: true,
          startTime: null,
          endTime: null
        },
        // singleSelect: {
        //   pushStatus: '未發佈',
        // }
      },
      recordAdvancedSearchData: {},
      // 單選篩選陣列
      singleSelectsArray: [],
    }
  },
  created() {
    this.initialization()
    this.getStoreLineGroupLinks()
  },
  mounted() {
    this.createModals(['batchSetStatusModal', 'advancedSearchModal'])
  },
  unmounted() {
    // 將 dataTable 的紀錄清除
    localStorage.removeItem('dt-state-makewishes-local')
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      // 紀錄 dataTabel 高度
      this.scrollHeight = window.innerHeight - 210
      // 將 dataTable 的紀錄清除
      localStorage.removeItem('dt-state-makewishes-local')
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
    },
    showModal(status) {
      if (status === 'batchSetStatus') {
        // 批次設定狀態
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆許願商品')
        this.propsSelectInfos.data = []
        this.propsSelectInfos.data = this.selectItems
        this.batchSetStatusModal.show()
      } else if (status === 'advancedSearch') {
        // 進階搜尋
        this.advancedSearchModal.show()
      }
    },
    // 展開全部
    expandAll() {
      this.expandedRows = this.wishesList.filter(item => item.id);
    },
    // 收合全部
    collapseAll() {
      this.expandedRows = []
    },
    // 取得 line 群組關聯
    getStoreLineGroupLinks() {
      this.dataTabelLoading = true
      this.$methods.switchLoading('show')
      const vm = this
      const getWishMerchandiseApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getStoreLineGroupLinks}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getWishMerchandiseApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.lineGroupLinkIds = []
            storeInfo.storeLineGroupLinks.forEach(item => {
              if (!item.deleted) vm.lineGroupLinkIds.push(item.id)
            })
            vm.computedD4TimeRange()
            vm.advancedSearch(false)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 計算預設時間區間
    computedD4TimeRange() {
      this.advancedSearchData.timeRange.endTime = this.$methods.moment().format('YYYY-MM-DD')
      this.advancedSearchData.timeRange.startTime = this.$methods.moment().subtract(90, 'days').format('YYYY-MM-DD')
    },
    // 進階搜尋
    advancedSearch(closeModal) {
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
      if (this.lineGroupLinkIds.length === 0) {
        this.dataTabelLoading = false
        this.$methods.switchLoading('hide')
        return
      }
      this.dataTabelLoading = true
      this.$methods.switchLoading('show')
      this.selectItems = []
      this.wishesList = []
      let query = {"columns":[{"columnName":"id","siteObjectType":63}],"distinct":true,"group":{"groups":[{"column":{"columnName":"storeLineGroupLinkId","siteObjectType":63},"operator":{"type":3,"value":this.lineGroupLinkIds}}],"operator":1},"siteObjectType":63,"sqlCommandType":1}
      // 時間區間
      if (this.advancedSearchData.timeRange.switch) {
        if (this.advancedSearchData.timeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.startTime} 00:00:00`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 63
            },
            operator: {
              operator: ">=",
              type: 1,
              value: {
                name: `from_unixtime(${startTime})`
              }
            }
          })
        }
        if (this.advancedSearchData.timeRange.endTime) {
          const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.endTime} 23:59:59`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 63
            },
            operator: {
              operator: "<=",
              type: 1,
              value: {
                name: `from_unixtime(${endTime})`
              }
            }
          })
        }
      }
      if (closeModal) this.advancedSearchModal.hide()
      this.getWishes(query)
    },
    // 取得許願商品列表
    getWishes(query) {
      const vm = this
      const getWishesApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          query: query,
          methods: '{getStoreLineGroupLink{getSummary},getWishVotes}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getWishesApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const wishesList = res.data[0].objects
            vm.sortOutWishes(wishesList)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 整理許願列表
    sortOutWishes(wishesList) {
      setTimeout(() => {
        wishesList.forEach(item => {
          const object = {
            creater: item.appUserName,
            completed: item.completed,
            country: item.country,
            createTime: this.$methods.moment(item.createTime).format('YYYY-MM-DD HH:mm:ss'),
            description: item.description,
            id: item.id,
            imageHash: item.imageHash,
            name: item.name,
            lineGroupName: item.storeLineGroupLink.summary.groupName,
            wishVotes: item.wishVotes,
            totalVotes: item.wishVotes.length
          }
          // 需特別處理
          object.wishVotes.forEach(body => {
            body.createTime = this.$methods.moment(body.createTime).format('YYYY-MM-DD HH:mm:ss')
          })
          this.wishesList.push(object)
        })
        this.dataTabelLoading = false
        this.$methods.switchLoading('hide')
      }, 1)
    },
    // 更新狀態
    updeteCompleted(mode, item) {
      const vm = this
      this.$methods.switchLoading('show')
      const updeteCompletedApi = `${process.env.VUE_APP_API}/apps/line/setWishMerchandiseCompleted`
      const token = this.serverToken
      const header = {
        authorization: token,
      }
      const data = new FormData()
      let wishMerchandiseIds = []
      let completedArr = []
      if (mode === 'single') {
        data.append('wishMerchandiseIds', item.id)
        data.append('completedArr', !item.completed)
      } else {
        this.selectItems.forEach(item => {
          wishMerchandiseIds.push(item.id)
          completedArr.push(!item.completed)
        })
        data.append('wishMerchandiseIds', wishMerchandiseIds.toString())
        data.append('completedArr', completedArr.toString())
      }
      $.ajax({
        type: 'PUT',
        async: true,
        url: updeteCompletedApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.wishesList.forEach(wish => {
              if (mode === 'single') {
                if (wish.id === item.id) wish.completed = !wish.completed
              } else {
                wishMerchandiseIds.forEach(selectId => {
                  if (selectId === wish.id) wish.completed = !wish.completed
                })
              }
            })
            vm.batchSetStatusModal.hide()
            vm.selectItems = []
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
  }
}
</script>